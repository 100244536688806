// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/notifications/components/NotificationButton.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/notifications/components/NotificationButton.tsx");
  import.meta.hot.lastModified = "1724162228711.5037";
}
// REMIX HMR END

import React from 'react';
import { Popover, PopoverButton, PopoverPanel, Transition, PopoverBackdrop } from '@headlessui/react';
import { offset, shift, useFloating } from '@floating-ui/react';
import { BellIcon } from '@heroicons/react/24/outline';
import { NotificationList } from './NotificationList';
import clsx from 'clsx';
export const NotificationButton = ({
  className = 'text-gray-400 hover:text-gray-600'
}) => {
  _s();
  const {
    refs,
    floatingStyles
  } = useFloating({
    placement: 'bottom',
    transform: false,
    middleware: [offset({
      mainAxis: 10,
      crossAxis: 0,
      alignmentAxis: 0
    }), shift()]
  });
  return <Popover>
      <PopoverButton
    // @ts-ignore hmm-kay
    ref={refs.setReference} className={clsx('focus:outline-none -m-1.5 p-2.5 flex items-center inline-flex items-center gap-x-1 text-sm font-semibold leading-6', className)}>
        <span className="sr-only">View notifications</span>
        <BellIcon className="h-6 w-6" aria-hidden="true" />
      </PopoverButton>
      <PopoverBackdrop className="fixed inset-0 bg-black opacity-30" />
      <Transition as={React.Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
        <PopoverPanel
      // @ts-ignore hmm-kay
      ref={refs.setFloating}
      // className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4"
      style={floatingStyles}>
          <div className="w-screen max-w-md overflow-x-hidden py-2 rounded-md flex-auto rounded-3xl bg-white dark:bg-slate-800 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 dark:ring-slate-400/5">
            <NotificationList />
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>;
};
_s(NotificationButton, "yf5c1DUaoqCxhOjCmzgGhV8T+Fs=", false, function () {
  return [useFloating];
});
_c = NotificationButton;
var _c;
$RefreshReg$(_c, "NotificationButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;