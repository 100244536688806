// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/channel/fetchers/channel-switch-fetcher.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/channel/fetchers/channel-switch-fetcher.tsx");
  import.meta.hot.lastModified = "1722960407044.454";
}
// REMIX HMR END

import React from 'react';
import { useFetcher, useRevalidator } from '@remix-run/react';
import { json } from '@remix-run/server-runtime';
import { listAvailableChannels } from '~admin/providers/channel/provider';
import { getActiveUser, setActiveUser } from './cookie.server';
import { getStaffByUserId } from '~admin/providers/staff/provider';
import { config } from '../config';
import { createChannelToken } from '../utils';
import { getAlertServer } from '~common/components/alert/server/index.server';
export async function loader({
  request,
  context
}) {
  let channelToken;
  let availableChannels;
  let error;
  try {
    const activeUser = await getActiveUser(request);
    channelToken = activeUser.get('channelToken');
    try {
      // we want to ensure superadmin or list channel permission, but it seems this permission
      // can only be read through an expensive query, so we're just aiming to catch an error here
      const activeUserId = activeUser.get('activeUserId');
      if (activeUserId) {
        // we don't have staff for all users, such as superadmin
        // this logs a nasty error server side
        const staff = await getStaffByUserId(activeUserId, {
          request
        });
        const staffUser = staff.rsv_staffByUserId;
        if (!staffUser) {
          throw new Error('Missing staff user');
        }
        const roleChannels = Object.values(staffUser.user.roles.reduce((acc, val) => {
          if (val.code === '__super_admin_role__') {
            throw new Error('Super admin user');
          }
          val.channels.forEach(c => {
            acc[`${c.token}`] = c;
          });
          return acc;
        }, {}));
        availableChannels = {
          items: roleChannels,
          totalItems: roleChannels.length
        };
      } else {
        throw new Error('Missing staff user');
      }
    } catch (err) {
      try {
        const res = await listAvailableChannels(undefined, {
          request
        });
        availableChannels = res.channels;
      } catch (err) {
        console.log('Error loading channels', err);
        availableChannels = {
          items: [],
          totalItems: 0
        };
      }
    }
  } catch (err) {
    error = err.message;
  }
  return json({
    channelToken,
    channels: availableChannels,
    error
  });
}
export async function action({
  request
}) {
  let error;
  let channelToken = null;
  const alertServer = getAlertServer();
  const url = new URL(request.url);
  if (url.searchParams.get('refresh')) {
    return;
  }
  const formData = await request.formData();
  const token = formData.get('token');
  const name = formData.get('name');
  const avatar = formData.get('avatar');
  const messagingClientToken = formData.get('messagingClientToken');
  console.log(`Switching channel to: ${name}, ${token}`);
  let headers;
  try {
    const activeUser = await getActiveUser(request);
    channelToken = name.length && token.length ? {
      name,
      token,
      avatar: avatar?.length ? avatar : undefined,
      messagingClientToken: messagingClientToken?.length ? messagingClientToken : null
    } : null;
    let cookie;
    if (activeUser) {
      await activeUser.set('channelToken', channelToken);
      cookie = await setActiveUser(activeUser);
    } else {
      cookie = await setActiveUser({
        activeUserId: null,
        channelToken
      });
    }
    headers = {
      'Set-Cookie': cookie
    };
  } catch (err) {
    error = err.message;
  }
  if (error) {
    return json({
      channelToken,
      error
    }, {
      headers
    });
  }
  return alertServer.redirectWithSuccess(`/?refresh=${Math.random() * 100000}`, {
    title: 'Success',
    message: `Changed channel to ${name}`
  }, {
    headers
  });
}
export const ChannelSwitchFetcher = ({
  children
}) => {
  _s();
  const [exception, setException] = React.useState();
  const loaderFetcher = useFetcher({
    key: 'channel-switch-loader'
  });
  const fetcher = useFetcher({
    key: 'channel-switch-action'
  });
  const revalidator = useRevalidator();
  const [token, setToken] = React.useState();
  const [items, setItems] = React.useState();
  const {
    data: loaderData,
    state: loaderState
  } = loaderFetcher;
  const {
    data: fetcherData,
    state: fetcherState
  } = fetcher;
  React.useEffect(() => {
    if (!fetcherData || fetcherData.error || fetcherState !== 'idle') {
      return;
    }
    if (fetcherData) {
      setException(undefined);
      setToken(fetcherData.channelToken);
      if (revalidator.state === 'idle') {
        // if the token changes. We should trigger a page reload
        revalidator.revalidate();
      }
    }
  }, [fetcherData, fetcherState]);
  React.useEffect(() => {
    if (!loaderData || loaderData.error || loaderState !== 'idle') {
      return;
    }
    if (loaderData) {
      setException(undefined);
      setToken(loaderData.channelToken);
      if (loaderData.channels) {
        setItems(loaderData.channels);
      }
    }
  }, [loaderData, loaderState]);
  React.useEffect(() => {
    if (fetcherData?.error) {
      setException(fetcherData.error);
    }
  }, [fetcherData]);
  React.useEffect(() => {
    if (loaderData?.error) {
      setException(loaderData.error);
    }
  }, [loaderData]);
  const handleLoadData = React.useCallback(() => {
    loaderFetcher.load(`${config.apiPath}/list`);
  }, []);
  const handleSwitch = React.useCallback(channelToken => {
    let newChannel = channelToken;
    if (!newChannel) {
      const firstChannel = items?.items[0];
      if (firstChannel) {
        newChannel = createChannelToken(firstChannel);
      }
    }
    fetcher.submit({
      name: newChannel?.name ?? '',
      token: newChannel?.token ?? '',
      avatar: newChannel?.avatar ?? '',
      messagingClientToken: newChannel?.messagingClientToken ?? ''
    }, {
      action: `${config.apiPath}/token`,
      method: 'POST'
    });
  }, [items]);
  const handleReload = React.useCallback(() => {
    handleLoadData();
  }, [handleLoadData]);
  React.useEffect(() => {
    if (!items) {
      handleReload();
    }
  }, []);
  React.useEffect(() => {
    if (items && loaderState === 'idle') {
      if (items.totalItems === 1 && items.items[0].code !== '__default_channel__') {
        // we want to set the channel to the available one if it is on the default and you don't have access to it
        handleSwitch(null);
      }
    }
  }, [items, loaderState]);
  return children({
    channels: items,
    channelToken: token,
    exception,
    loading: fetcherState !== 'idle' || loaderState !== 'idle',
    onChange: handleSwitch,
    onReload: handleReload
  });
};
_s(ChannelSwitchFetcher, "9i3pISRpIIElOVvYiQ22Fc2ckvs=", false, function () {
  return [useFetcher, useFetcher, useRevalidator];
});
_c = ChannelSwitchFetcher;
var _c;
$RefreshReg$(_c, "ChannelSwitchFetcher");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;