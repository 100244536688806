// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/notifications/components/NotificationList.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/notifications/components/NotificationList.tsx");
  import.meta.hot.lastModified = "1726514160369.9338";
}
// REMIX HMR END

import React from 'react';
import { Error } from '~common/components/Error';
import { Loading } from '~common/components/loading/Loading';
import { HistoryEntryType } from '~admin/generated/graphql';
import { InfiniteScroller } from '~common/components/scroll/InfiniteScroller';
import { NativeSelect } from '~ui';
import { notificationTitle } from '../config';
import { Notification } from './Notification';
import { NotificationListFetcher } from '../fetchers/notification-list-fetcher';
import { Input } from '~ui';
const sort = (a1, a2) => {
  if (a1.label < a2.label) {
    return -1;
  }
  if (a1.label > a2.label) {
    return 1;
  }
  return 0;
};
const options = Object.values(HistoryEntryType).map(type => ({
  label: notificationTitle(type),
  value: type.toString()
})).sort(sort);
const Select = React.memo(_c = ({
  value,
  onChange
}) => {
  return <NativeSelect name="historyEntryType" value={value} onChange={onChange}>
      {options.map(o => <option key={o.value} value={o.value}>
          {o.label}
        </option>)}
    </NativeSelect>;
});
_c2 = Select;
export const NotificationList = ({
  maxItems
}) => {
  _s();
  const [type, setType] = React.useState();
  const [filter, setFilter] = React.useState();
  const [textInput, setTextInput] = React.useState('');
  return <NotificationListFetcher maxItems={maxItems}>
      {({
      items,
      itemCount,
      loading,
      exception,
      onSearch,
      onNext,
      onReload
    }) => {
      let content;
      if (loading) {
        content = <Loading />;
      } else if (exception) {
        content = <Error onReload={onReload} />;
      }
      return <>
            <div className="pb-2 px-4">
              <Input name="search-input" value={textInput} placeholder="Customer name, email, order code" onChange={event => {
            const txt = event.target.value;
            setTextInput(txt);
            const trimmedTxt = txt.trim();
            const filter = trimmedTxt.length ? {
              ['emailAddress']: {
                regex: trimmedTxt
              },
              ['firstName']: {
                regex: trimmedTxt
              },
              ['lastName']: {
                regex: trimmedTxt
              },
              ['orderCode']: {
                regex: trimmedTxt
              }
            } : undefined;
            setFilter(filter);
            onSearch(trimmedTxt.length ? {
              types: type ? [type] : undefined,
              filter
            } : {
              types: type ? [type] : undefined
            });
          }} className="w-full mb-1" />
              <Select value={type} onChange={event => {
            const value = event?.target?.value;
            setType(value?.length ? value : undefined);
            onSearch({
              types: value?.length ? [value] : undefined,
              filter
            });
          }} />
            </div>
            <InfiniteScroller loading={loading} loadNext={onNext}>
              <ul role="list" className="p-2 divide-y divide-gray-100 dark:divide-slate-600">
                {items.map((item, idx) => <Notification key={`${item.id}-${idx}`} item={item} iconSize="large" />)}
              </ul>
              {content}
            </InfiniteScroller>
          </>;
    }}
    </NotificationListFetcher>;
};
_s(NotificationList, "4WGcZLxX+atTWtalPikNleLuOW4=");
_c3 = NotificationList;
var _c, _c2, _c3;
$RefreshReg$(_c, "Select$React.memo");
$RefreshReg$(_c2, "Select");
$RefreshReg$(_c3, "NotificationList");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;