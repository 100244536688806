// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/layout/Layout.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/layout/Layout.tsx");
  import.meta.hot.lastModified = "1724965187154.5554";
}
// REMIX HMR END

import React from 'react';
import { Searchbar } from './Searchbar';
import { LeftMenu } from './LeftMenu';
import { Breadcrumb } from './Breadcrumb';
import { useLocation } from '@remix-run/react';
export const Layout = ({
  administrator,
  channel,
  socketActive,
  onSignOut,
  children
}) => {
  _s();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const location = useLocation();
  const handleSidebarToggle = React.useCallback(open => {
    setSidebarOpen(val => typeof open !== 'undefined' ? open : !val);
  }, []);
  return <div>
      <div className="lg:pl-20 xs:pl-8">
        <Searchbar administrator={administrator} channel={channel} socketActive={socketActive} onSignOut={onSignOut} onSidebarOpen={() => handleSidebarToggle(true)} />
        {location.pathname !== '/' && !location.pathname.startsWith('/report') && !/^\/dashboard\/customers\/[^/]+\/details$/.test(location.pathname) && <>
            <div className="fixed top-16 min-w-full z-10">
              <Breadcrumb />
            </div>
            <div className="mb-24 md:mb-16"></div>
          </>}
        <main className="mx-auto">
          <div className="mx-auto">{children}</div>
        </main>
      </div>
      <LeftMenu administrator={administrator} isOpen={sidebarOpen} onToggleSidebar={handleSidebarToggle} />
    </div>;
};
_s(Layout, "pqeR4f62J3YHT2r5FBrUtI7mKcU=", false, function () {
  return [useLocation];
});
_c = Layout;
var _c;
$RefreshReg$(_c, "Layout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;